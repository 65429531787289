// @ts-nocheck

const cookieManager = {
  // Create a cookie
  create: function (name, value, days, sessionOnly) {
    var expires = '';

    if (sessionOnly) expires = '; expires=0';
    else if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toGMTString();
    }

    document.cookie = name + '=' + value + expires + '; path=/';
  },

  // Get the value of a cookie
  get: function (name) {
    if (typeof document === 'undefined') return null;

    var nameEQ = name + '=';
    var ca = document.cookie.split(';');

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  },

  // Delete a cookie
  erase: function (name) {
    this.create(name, '', -1);
  },
};

export default cookieManager;
