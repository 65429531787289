/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import queryString from 'query-string';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';

import { getCookie, isBrowser } from './src/utils/common';
import cookieManager from './src/containers/Layout/components/ExitIntentModal/cookieManager';

export const onRouteUpdate = ({ location, prevLocation }) => {
  document.body.classList.remove('loaded');
  document.body.classList.remove('fixed-scroll');
  document.body.style.paddingRight = ``;

  //  if (process.env.GOOGLE_TAG_MANAGER_ID) {
  if (!prevLocation) {
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        setTimeout(initGTM, 3500);
      }
    };

    document.addEventListener('scroll', loadGTM);
    document.addEventListener('mousemove', loadGTM);
    document.addEventListener('touchstart', loadGTM);
  } else {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'gatsby-route-change',
      });
    }
  }
  //  }
};

function loadGtmOld() {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l !== 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(
    window,
    document,
    'script',
    'dataLayer',
    //process.env.GOOGLE_TAG_MANAGER_ID
    'GTM-K58JNDR'
  );
}

function initGTM() {
  if (window.isGTMLoaded) {
    return false;
  }

  loadGtmOld();

  setTimeout(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'gatsby-route-change',
      });

      window.dataLayer.push({
        event: 'pageIsIdle',
      });
    }
  }, 3500);

  window.isGTMLoaded = true;
}

function loadGTM(event) {
  initGTM();
  event.currentTarget.removeEventListener(event.type, loadGTM);
}

export const onClientEntry = () => {
  const parsed = queryString.parse(document.location.search);

  if (parsed.utm_source) {
    localStorage.setItem('utm_source', parsed.utm_source);
  }

  if (parsed.utm_medium) {
    localStorage.setItem('utm_medium', parsed.utm_medium);
  }

  if (parsed.utm_campaign) {
    localStorage.setItem('utm_campaign', parsed.utm_campaign);
  }

  if (parsed.utm_content) {
    localStorage.setItem('utm_content', parsed.utm_content);
  }

  if (isBrowser()) {
    if (!cookieManager.get('wa_landing_page')) {
      cookieManager.create('wa_landing_page', window.location.href, 365, false);

      cookieManager.create(
        'wa_first_visit_time',
        new Date().getTime(),
        365,
        false
      );
    }
  }

  const gclid = getCookie('captureGclid');
  if (gclid) {
    document.querySelectorAll('a').forEach((linkNode) => {
      if (
        !linkNode
          .getAttribute('href')
          ?.startsWith('https://api.whip-around.com')
      ) {
        return;
      }

      let linkHref = linkNode.getAttribute('href');
      if (!linkHref) {
        return;
      }

      if (linkHref.includes('?')) {
        linkHref = linkHref + '&gclid=' + gclid;
      } else {
        linkHref = linkHref + '?gclid=' + gclid;
      }

      linkNode.setAttribute('href', linkHref);
    });
  }
};
